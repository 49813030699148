exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cloud-match-js": () => import("./../../../src/pages/cloud_match.js" /* webpackChunkName: "component---src-pages-cloud-match-js" */),
  "component---src-pages-compare-cloud-66-vs-fly-js": () => import("./../../../src/pages/compare/cloud66-vs-fly.js" /* webpackChunkName: "component---src-pages-compare-cloud-66-vs-fly-js" */),
  "component---src-pages-compare-cloud-66-vs-heroku-js": () => import("./../../../src/pages/compare/cloud66-vs-heroku.js" /* webpackChunkName: "component---src-pages-compare-cloud-66-vs-heroku-js" */),
  "component---src-pages-compare-cloud-66-vs-netlify-js": () => import("./../../../src/pages/compare/cloud66-vs-netlify.js" /* webpackChunkName: "component---src-pages-compare-cloud-66-vs-netlify-js" */),
  "component---src-pages-compare-cloud-66-vs-render-js": () => import("./../../../src/pages/compare/cloud66-vs-render.js" /* webpackChunkName: "component---src-pages-compare-cloud-66-vs-render-js" */),
  "component---src-pages-compare-cloud-66-vs-vercel-js": () => import("./../../../src/pages/compare/cloud66-vs-vercel.js" /* webpackChunkName: "component---src-pages-compare-cloud-66-vs-vercel-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-for-express-js": () => import("./../../../src/pages/for/express.js" /* webpackChunkName: "component---src-pages-for-express-js" */),
  "component---src-pages-for-other-js": () => import("./../../../src/pages/for/other.js" /* webpackChunkName: "component---src-pages-for-other-js" */),
  "component---src-pages-for-php-js": () => import("./../../../src/pages/for/php.js" /* webpackChunkName: "component---src-pages-for-php-js" */),
  "component---src-pages-for-rails-js": () => import("./../../../src/pages/for/rails.js" /* webpackChunkName: "component---src-pages-for-rails-js" */),
  "component---src-pages-for-react-js": () => import("./../../../src/pages/for/react.js" /* webpackChunkName: "component---src-pages-for-react-js" */),
  "component---src-pages-for-static-js": () => import("./../../../src/pages/for/static.js" /* webpackChunkName: "component---src-pages-for-static-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-opensource-software-js": () => import("./../../../src/pages/opensource-software.js" /* webpackChunkName: "component---src-pages-opensource-software-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-templates-bare-js": () => import("./../../../src/templates/bare.js" /* webpackChunkName: "component---src-templates-bare-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */)
}

